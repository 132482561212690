import React from "react";
function Thunder({className}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      aria-hidden="true"
      width="1.5rem"
      height="1.5rem"
      viewBox="0 0 24 24"
 
    >
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M13 10V3L5 14h6v7l8-11z"
      />
    </svg>
  );
}

export default Thunder;