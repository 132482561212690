import React, { useEffect, useRef } from 'react';

const ClickSpark = () => {
  const sparkRef = useRef(null);
  const svgRef = useRef(null);

  useEffect(() => {
    const parentElement = sparkRef.current.parentNode;

    const handleEvent = (e) => {
      setSparkPosition(e);
      animateSpark();
    };

    parentElement.addEventListener('click', handleEvent);
    return () => {
      parentElement.removeEventListener('click', handleEvent);
    };
  }, []);

  const animateSpark = () => {
    const sparks = Array.from(svgRef.current.children);
    const size = parseInt(sparks[0].getAttribute('y1'));
    const offset = size / 2 + 'px';

    const keyframes = (i) => {
      const deg = `calc(${i} * (360deg / ${sparks.length}))`;
      return [
        {
          strokeDashoffset: size * 3,
          transform: `rotate(${deg}) translateY(${offset})`
        },
        {
          strokeDashoffset: size,
          transform: `rotate(${deg}) translateY(0)`
        }
      ];
    };

    const options = {
      duration: 660,
      easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
      fill: 'forwards'
    };

    sparks.forEach((spark, i) => spark.animate(keyframes(i), options));
  };

  const setSparkPosition = (e) => {
    sparkRef.current.style.left = e.pageX - sparkRef.current.clientWidth / 2 + 'px';
    sparkRef.current.style.top = e.pageY - sparkRef.current.clientHeight / 2 + 'px';
  };

  const createSpark = () => {
    return (
      <>
        <style>
          {`
            .spark-container {
              position: absolute;
              pointer-events: none;
            }
          `}
        </style>
        <svg
          ref={svgRef}
          width="30"
          height="30"
          viewBox="0 0 100 100"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          stroke="var(--click-spark-color, currentcolor)"
          transform="rotate(-20)"
        >
          {Array.from({ length: 8 }, (_, i) => (
            <line
              key={i}
              x1="50"
              y1="30"
              x2="50"
              y2="4"
              strokeDasharray="30"
              strokeDashoffset="30"
              style={{ transformOrigin: 'center' }}
            />
          ))}
        </svg>
      </>
    );
  };

  return (
    <div className="spark-container z-50" ref={sparkRef}>
      {createSpark()}
    </div>
  );
};

export default ClickSpark;
