import React from "react";
function Playstore(props) {
  const { width, height } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg"  width={width}
      height={height} viewBox="0 0 133 133" {...props}>
      <path
        fill="#47bcff"
        d="M16.779 3.052l74.857 43.207 28.819 16.649c2.704 1.627 2.726 5.514.042 7.162l-.127.085-103.528 59.771-.085.042a4.176 4.176 0 01-3.232.359c-1.69-.507-3.022-2.049-3.022-4.056V6.728c0-2.007 1.331-3.549 3.022-4.056 1.035-.297 2.197-.233 3.254.38z"
      />
      <path
        fill="#0bdf74"
        d="M16.779 3.052l74.857 43.207L72.832 66.5 13.525 2.671c1.035-.296 2.197-.232 3.254.381z"
      />
      <path
        fill="#fdba21"
        d="M91.636 46.259l28.819 16.649c2.704 1.627 2.726 5.514.042 7.162l-.127.085-28.734 16.564L72.832 66.5l18.804-20.241z"
      />
      <path
        fill="#fb434c"
        d="M72.832 66.5l18.804 20.22-74.794 43.207-.085.042a4.176 4.176 0 01-3.232.359L72.832 66.5z"
      />
    </svg>
  );
}


export default Playstore;