import React from 'react';

const NoJobs = () => {
  return (
    <div className='h-[80vh] flex justify-center flex-col'>
      <div className='flex justify-center pb-16'>
        <img src='https://raw.githubusercontent.com/kwapio/logo/main/assets/sad-face-emoji.gif' width={150} alt='nojobs'/>
      </div>
      <h2 className="text-2xl text-gray-600 font-bold md:text-2xl text-center">Sorry at this time, we do not have any job vacancies available.</h2>
      <h2 className="text-2xl text-gray-600 font-bold md:text-2xl text-center pb-5">For future opportunities, we recommend reaching out to <a href='mailto:growth@qubernet.com' className='underline text-pink-600'> growth@qubernet.com</a>
      </h2>

      <div className='flex justify-center'>
        <button className='mt-4 bg-kogreen-dark p-3 text-gray-50 rounded mb-4'>
          <a href='/career'>Go Back</a>
        </button>
      </div>
    </div>
  );
};

export default NoJobs;
