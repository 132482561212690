import React from 'react';
import Pitchworks from './brands/pitchworks.webp';
import VA from './brands/va.svg';
import KLS from './brands/kls.png';
import styles from './cus.module.scss';
export default function Customers() {
  const brands=[{
    name:'Pitchworks',
    icon:Pitchworks
  },{
    name:'videoassistant',
    icon:VA
  },
  {
    name:'qubernet',
    icon:'https://qubernet.com/assets/images/logo.svg'
  },{
    name:'Klubstack',
    icon:KLS
  }];
  return (
    <div className={styles.wrapper}>
      <h6>Trusted by a wide range of brands globally.</h6>
      <div className={styles.cList}>
        {brands.map((brand)=>
          <div key={brand.name}>
            <img src={brand.icon} alt={brand.name}/>
          </div>
        )}
      </div>
    </div>
  );
}
