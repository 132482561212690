import React from "react";
function ThreeDash(props) {
  return (
    <svg
      className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-10dohqv"
      aria-hidden="true"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        opacity={0.32}
        d="M15.78 4.5H5.22c-.948 0-1.72.56-1.72 1.25S4.272 7 5.22 7h10.56c.948 0 1.72-.56 1.72-1.25s-.772-1.25-1.72-1.25z"
        fill="currentColor"
      />
      <path
        d="M18.78 10.75H8.22c-.948 0-1.72.56-1.72 1.25s.772 1.25 1.72 1.25h10.56c.948 0 1.72-.56 1.72-1.25s-.772-1.25-1.72-1.25zM15.78 17H5.22c-.948 0-1.72.56-1.72 1.25s.772 1.25 1.72 1.25h10.56c.948 0 1.72-.56 1.72-1.25S16.728 17 15.78 17z"
        fill="currentColor"
      />
    </svg>
  );
}


export default ThreeDash;