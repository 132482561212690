import React from 'react';

const Logocrystal = (props) => {
  const { width, height } = props;
  return (
    <svg
      width={width}
      height={height}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 128 128"
      {...props}
    >
      <defs>
        <style>
          {`
            .cls-1{fill:#5fbd70; animation: pulse1 5s infinite;}
            .cls-2{fill:#77449a; animation: pulse2 5s infinite 0.4s;}
            .cls-3{fill:#82c352; animation: pulse3 5s infinite 0.8s;}
            .cls-4{fill:#5f5ca7; animation: pulse4 5s infinite 1.2s;}
            .cls-5{fill:#afc136; animation: pulse5 5s infinite 1.6s;}
            .cls-6{fill:#4f73b7; animation: pulse6 5s infinite 2s;}
            .cls-7{fill:#dcc125; animation: pulse7 5s infinite 2.4s;}
            .cls-8{fill:#4f8ecb; animation: pulse8 5s infinite 2.8s;}
            .cls-9{fill:#d2516c; animation: pulse9 5s infinite 3.2s;}
            .cls-10{fill:#34a5cd; animation: pulse10 5s infinite 3.6s;}
            .cls-11{fill:#a7458b; animation: pulse11 5s infinite 4s;}
            .cls-12{fill:#44b49e; animation: pulse12 5s infinite 4.4s;}

            @keyframes pulse1 {
              0%, 100% { transform: scale(1); }
              50% { transform: scale(1.1); }
            }
            @keyframes pulse2 {
              0%, 100% { transform: scale(1); }
              50% { transform: scale(1.1); }
            }
            @keyframes pulse3 {
              0%, 100% { transform: scale(1); }
              50% { transform: scale(1.1); }
            }
            @keyframes pulse4 {
              0%, 100% { transform: scale(1); }
              50% { transform: scale(1.1); }
            }
            @keyframes pulse5 {
              0%, 100% { transform: scale(1); }
              50% { transform: scale(1.1); }
            }
            @keyframes pulse6 {
              0%, 100% { transform: scale(1); }
              50% { transform: scale(1.1); }
            }
            @keyframes pulse7 {
              0%, 100% { transform: scale(1); }
              50% { transform: scale(1.1); }
            }
            @keyframes pulse8 {
              0%, 100% { transform: scale(1); }
              50% { transform: scale(1.1); }
            }
            @keyframes pulse9 {
              0%, 100% { transform: scale(1); }
              50% { transform: scale(1.1); }
            }
            @keyframes pulse10 {
              0%, 100% { transform: scale(1); }
              50% { transform: scale(1.1); }
            }
            @keyframes pulse11 {
              0%, 100% { transform: scale(1); }
              50% { transform: scale(1.1); }
            }
            @keyframes pulse12 {
              0%, 100% { transform: scale(1); }
              50% { transform: scale(1.1); }
            }
          `}
        </style>
      </defs>
      <path
        className="cls-1"
        d="M63.72 41.66L59.21 16.42 63.72 8.08 68.24 16.42 63.72 41.66z"
      />
      <path
        className="cls-2"
        d="M63.72 87.52L68.24 112.77 63.72 121.11 59.21 112.77 63.72 87.52z"
      />
      <path
        className="cls-3"
        d="M52.31 44.45L35.77 24.85 35.52 15.37 43.6 20.34 52.31 44.45z"
      />
      <path
        className="cls-4"
        d="M75.24 84.17L91.77 103.78 92.03 113.26 83.95 108.29 75.24 84.17z"
      />
      <path
        className="cls-5"
        d="M43.82 52.58L19.7 43.87 14.73 35.79 24.21 36.05 43.82 52.58z"
      />
      <path
        className="cls-6"
        d="M83.53 75.51L107.65 84.22 112.62 92.3 103.14 92.04 83.53 75.51z"
      />
      <path
        className="cls-7"
        d="M40.53 63.86L15.29 68.38 6.94 63.86 15.29 59.34 40.53 63.86z"
      />
      <path
        className="cls-8"
        d="M86.39 63.86L111.63 59.34 119.98 63.86 111.63 68.38 86.39 63.86z"
      />
      <path
        className="cls-9"
        d="M43.32 75.28L23.72 91.81 14.23 92.07 19.2 83.99 43.32 75.28z"
      />
      <path
        className="cls-10"
        d="M83.04 52.35L102.64 35.81 112.12 35.55 107.16 43.63 83.04 52.35z"
      />
      <path
        className="cls-11"
        d="M51.45 83.76L42.73 107.88 34.65 112.85 34.91 103.37 51.45 83.76z"
      />
      <path
        className="cls-12"
        d="M74.38 44.05L83.09 19.93 91.17 14.96 90.91 24.44 74.38 44.05z"
      />
      <path
        className="cls-12"
        d="M77.68 3.38L80.34 0 81.17 4.22 76.64 15.31 77.68 3.38z"
      />
      <path
        className="cls-11"
        d="M45.99 123.78L50.41 112.65 49.49 124.59 46.86 128 45.99 123.78z"
      />
      <path
        className="cls-1"
        d="M45.62 4.53L46.23 0.27 49.06 3.51 50.68 15.38 45.62 4.53z"
      />
      <path
        className="cls-2"
        d="M78.38 124.64L76.63 112.79 81.81 123.59 81.23 127.86 78.38 124.64z"
      />
      <path
        className="cls-3"
        d="M18.42 21.55L16.82 17.55 20.9 18.95 28.23 28.41 18.42 21.55z"
      />
      <path
        className="cls-4"
        d="M106.85 109.19L99.42 99.8 109.3 106.57 110.93 110.55 106.85 109.19z"
      />
      <path
        className="cls-5"
        d="M3.38 49.89L0 47.23 4.22 46.4 15.31 50.93 3.38 49.89z"
      />
      <path
        className="cls-6"
        d="M112.65 77.16L124.59 78.08 128 80.71 123.78 81.58 112.65 77.16z"
      />
      <path
        className="cls-7"
        d="M0.27 81.34L3.51 78.51 15.38 76.89 4.53 81.95 0.27 81.34z"
      />
      <path
        className="cls-8"
        d="M123.59 45.76L127.86 46.34 124.64 49.2 112.79 50.94 123.59 45.76z"
      />
      <path
        className="cls-9"
        d="M18.95 106.67L28.41 99.34 21.55 109.15 17.55 110.75 18.95 106.67z"
      />
      <path
        className="cls-10"
        d="M106.57 18.27L110.55 16.64 109.19 20.72 99.8 28.15 106.57 18.27z"
      />
    </svg>
  );
};

export default Logocrystal;
