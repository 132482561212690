import Applestore from 'files/apple';
import Playstore from 'files/playstore';
import Thunder from 'files/thunder';
import { useEffect, useState } from 'react';
import styles from './accordion.module.scss';

const Accordion  = ({progressWidth}) => {
  const Messages = [`Kwapio has transformed how our team collaborates and manages projects. The platform's all-in-one approach eliminates the hassle of juggling multiple tools, making our workflow more efficient and streamlined. We especially appreciate the integrated project management and HR features, which have significantly improved our productivity. The user-friendly interface is intuitive, and the pricing is fair, making it a great value for our business. Overall, Kwapio has become an essential part of our daily operations.`,
    `Kwapio has been a game-changer for our team. Its unified platform brought all our project management, communication, and HR tools into one place, simplifying our processes and saving us time. The ease of use and seamless integration of features have greatly improved our workflow. We’ve seen a noticeable boost in productivity and collaboration since adopting Kwapio. It’s an invaluable tool for any business looking to streamline operations`];

  const [, setFade] = useState(true);
  const [, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setIndex((prevIndex) => (prevIndex + 1) % Messages.length);
        setFade(true);
      }, 1000);
    }, 3000);

    return () => clearInterval(interval);
  }, [Messages.length]);
  return (
    <>
      
      <section className="text-gray-700 body-font" >
        <div className="container mx-auto flex px-5 md:flex-row flex-col items-center">
          <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900" data-aos="fade-up">You are halfway there
              <br className="hidden lg:inline-block"/> Still not sure about Kwapio?
            </h1>
            <p className="mb-8 leading-relaxed" data-aos="fade-up">Okay, let&#39;s take a look at the remaining features, and We will make sure you won&#39;t regret it in the end.</p>
            <div className="flex justify-center">
              <span className={`${styles.primaryColorBtn} cursor-pointer rounded-md inline-flex bg-gray-950 px-5 py-3.5 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`} onClick={()=>window.location.href = "https://app.kwapio.com/account/create-account"} data-aos="fade-up">
                <Thunder />
                <span className='ms-2'> Get started</span>
              </span>
            </div>
          </div>
          <div className="lg:max-w-2xl lg:w-full md:w-1/2 w-5/6">
            <img className="object-cover object-center rounded" alt="hero" src="https://raw.githubusercontent.com/kwapio/logo/main/assets/Integration.png"/>
          </div>
        </div>
      </section>
      
      <section className="text-gray-700 body-font" id='mobile'>
        <div className="container mx-auto flex px-5 md:flex-row flex-col items-center">
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 ">

            {progressWidth >66 &&
            <img className={`${styles.sideman} absolute`} alt="side" src="https://raw.githubusercontent.com/kwapio/logo/main/assets/sideholder.png"/>
            }

            <img className="object-cover object-center relative rounded z-40" alt="hero" src="https://raw.githubusercontent.com/kwapio/logo/main/assets/app.png"/>
          </div>
          
          <div className="lg:ps-16 lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900" data-aos="fade-up">Kwapio Mobile Application
            </h1>
            <p className="mb-8 leading-relaxed" data-aos="fade-up">Kwapio is your go-to mobile application designed to transform the way you manage your personal finances, connect with a global network of professionals streamline your productivity tasks. With a user-friendly interface and powerful features, Kwapio aims to make your life easier and more efficient.</p>
            <h6 className="mt-9 font-semibold text-gray-400">Available Platform</h6>

            <div className="mt-5 flex items-center hover:drop-shadow-lg justify-center" data-aos="fade-up"  data-aos-duration="800"  data-aos-delay="150" >
              <button type="button" className={`bg text-white bg-gray-900 hover:bg-gray-900/90 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 mb-2`}>
                <Playstore width={40} height={40} className="w-7 h-7 me-2"/>Play Store
              </button>
              <button type="button" className="text-white bg bg-gray-900 hover:bg-gray-900/90 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 mb-2">
                <Applestore width={30} height={30} className="w-7 h-7 me-2"/>App Store
              </button>

                
            </div>
          </div>
         
        </div>
      </section>


     
      <section className="text-gray-700 body-font border-t border-gray-200">
        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-wrap w-full mb-20 flex-col items-center text-center">
            <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900" data-aos="fade-up">Kwapio: The Solution to Fragmented Collaboration</h1>
            <p className="lg:w-1/2 w-full leading-relaxed text-base" data-aos="fade-up">Streamlining Workflows for Enhanced Productivity         </p>
          </div>
          <div className="flex flex-wrap -m-4">
            <div className="xl:w-1/3 md:w-1/2 p-4">
              <div className="border border-gray-300 p-6 rounded-lg" data-aos="fade-up">
                <div className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
                  <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-6 h-6" viewBox="0 0 24 24">
                    <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                  </svg>
                </div>
                <h2 className="text-lg text-gray-900 font-medium title-font mb-2">Unified, All-in-One Platform </h2>
                <p className="leading-relaxed text-base">Experience a cohesive workspace where all your tools come together seamlessly.</p>
              </div>
            </div>
            <div className="xl:w-1/3 md:w-1/2 p-4">
              <div className="border border-gray-300 p-6 rounded-lg" data-aos="fade-up">
                <div className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
                  <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-6 h-6" viewBox="0 0 24 24">
                    <circle cx="6" cy="6" r="3"></circle>
                    <circle cx="6" cy="18" r="3"></circle>
                    <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
                  </svg>
                </div>
                <h2 className="text-lg text-gray-900 font-medium title-font mb-2">Streamlining and Enhancing Productivity</h2>
                <p className="leading-relaxed text-base">Improve efficiency, and ultimately enhance productivity across all levels.</p>
              </div>
            </div>
            <div className="xl:w-1/3 md:w-1/2 p-4">
              <div className="border border-gray-300 p-6 rounded-lg" data-aos="fade-up">
                <div className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
                  <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-6 h-6" viewBox="0 0 24 24">
                    <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                    <circle cx="12" cy="7" r="4"></circle>
                  </svg>
                </div>
                <h2 className="text-lg text-gray-900 font-medium title-font mb-2">Task and Project Organization</h2>
                <p className="leading-relaxed text-base">Efficiently manage tasks and projects, bringing order to your workflows.</p>
              </div>
            </div>
            <div className="xl:w-1/3 md:w-1/2 p-4">
              <div className="border border-gray-300 p-6 rounded-lg" data-aos="fade-up">
                <div className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
                  <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-6 h-6" viewBox="0 0 24 24">
                    <path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1zM4 22v-7"></path>
                  </svg>
                </div>
                <h2 className="text-lg text-gray-900 font-medium title-font mb-2">Human-Centered Methodologies</h2>
                <p className="leading-relaxed text-base">Prioritize people in processes, fostering a collaborative and inclusive environment.</p>
              </div>
            </div>
            <div className="xl:w-1/3 md:w-1/2 p-4">
              <div className="border border-gray-300 p-6 rounded-lg" data-aos="fade-up">
                <div className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
                  <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-6 h-6" viewBox="0 0 24 24">
                    <path d="M21 12.79A9 9 0 1111.21 3 7 7 0 0021 12.79z"></path>
                  </svg>
                </div>
                <h2 className="text-lg text-gray-900 font-medium title-font mb-2">Real-time Communication</h2>
                <p className="leading-relaxed text-base">Facilitate instant communication, reducing delays and enhancing collaboration.</p>
              </div>
            </div>
            <div className="xl:w-1/3 md:w-1/2 p-4">
              <div className="border border-gray-300 p-6 rounded-lg" data-aos="fade-up">
                <div className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
                  <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-6 h-6" viewBox="0 0 24 24">
                    <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                  </svg>
                </div>
                <h2 className="text-lg text-gray-900 font-medium title-font mb-2">Anywhere, Anytime Accessibility</h2>
                <p className="leading-relaxed text-base">Empower teams to work seamlessly, regardless of their location or time zone.</p>
              </div>
            </div>
          </div>
          <button className="flex mx-auto mt-16 text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg">Button</button>
        </div>
      </section>
      {/*     
     
      <section className="text-gray-700 body-font border-t border-gray-200">
        <div className="container px-5 py-24 mx-auto">
          <div className="xl:w-1/2 lg:w-3/4 w-full mx-auto text-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="inline-block w-8 h-8 text-gray-400 mb-8" viewBox="0 0 975.036 975.036">
              <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
            </svg>
            <p className={`leading-relaxed text-lg ${fade ? styles.fadeIn : styles.fadeOut} ${styles.dynamicText}`}>{Messages[index]}</p>
            <span className="inline-block h-1 w-10 rounded bg-indigo-500 mt-8 mb-6"></span>
            <h2 className="text-gray-900 font-medium title-font tracking-wider text-sm">Sed ut perspiciatis</h2>
            <p className="text-gray-500">Sed ut perspiciatis</p>
          </div>
        </div>
      </section>
       */}
    
    </>
  );
};

export default Accordion;
