import { Dialog, DialogPanel } from '@headlessui/react';
import Logo from 'files/logo';
import MobileMenuClose from 'files/menuclose';
import ThreeDash from 'files/threedash';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import ScrollIndicator from '../scrollIndicator';
import styles from '../website.module.scss';
import Settings from 'files/settings';
import Thunder from 'files/thunder';


const NavBar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [progressWidth, setProgressWidth] = useState();


  useEffect(() => {
    const handleScroll = () => {
      const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop;
      const height =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      const scrolled = (winScroll / height) * 100;
      setProgressWidth(scrolled);

    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
    
  }, []);

  const navigation = [
    { name: 'Home', href: '/' },
    { name: 'About Us', href: '/#about' },
    { name: 'Career', href: 'career' },
    { name: 'Contact', href: '/#contact' }
  ];
  return (
    <header className="sticky inset-x-0 top-0 z-50">
      <ScrollIndicator progressWidth={progressWidth} />
      <nav aria-label="Global" className={`${styles.navBlur} lg:p-2 p-3 flex items-center justify-between lg:px-8`}>
        <div className="flex lg:flex-1">
          <NavLink to="/" className="-m-1.5 p-1.5">
            <Logo width={120} height={40} />
          </NavLink>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            onClick={() => setMobileMenuOpen(true)}
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
          >
            <span className="sr-only">Open main menu</span>
            <ThreeDash aria-hidden="true" className="h-6 w-6" />
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          {navigation.map((item) => (
            <a
              key={item.name}
              href={`${item.href}`}
              className={`${styles.navHover} text-sm font-semibold leading-6 text-gray-900 `}
            >
              {item.name}
            </a>
          ))}
          <span
            className={
                `${styles.navHover} cursor-pointer text-sm font-semibold leading-6 text-gray-900}`
            }
            onClick={() => (window.location.href = 'https://app.kwapio.com')}
          >
            Login
          </span>

          <span
            className={
                `${styles.navHover} cursor-pointer text-sm font-semibold leading-6 text-gray-900}`
            }
            onClick={() => (window.location.href = 'https://app.kwapio.com/support/tickets/acc-16e309299f/wos2774aed595')}
          >
            Support
          </span>
         
        </div>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          
          <span className={`cursor-pointer rounded-md inline-flex ${styles.primaryColorBtn} text-sm text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`} onClick={()=>window.location.href = "https://app.kwapio.com/account/create-account"}>
            <Thunder className={styles.thunderIcon}/>
            <span className='ms-2'> Get started</span>
          </span>
          
          <a
            href="mailto:kamal@qubernet.com"
            target="_blank"
            rel="noreferrer"
            className={`bg-white text-gray-950 rounded-md hover:drop-shadow-lg ml-2 inline-flex ${styles.btnTwo} border-black border-2 px-5 py-3.5 text-sm shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
          >
            <Settings className={styles.spin} />
            <span className="ms-2">Request Demo</span>
          </a>

        </div>
      </nav>
      <Dialog open={mobileMenuOpen} onClose={() => setMobileMenuOpen(false)} className="lg:hidden">
        <div className="fixed inset-0 z-50" />
        <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <NavLink to="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Kwapio</span>
              <Logo width={120} height={40} />
            </NavLink>
            <button
              type="button"
              onClick={() => setMobileMenuOpen(false)}
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
            >
              <span className="sr-only">Close menu</span>
              <MobileMenuClose aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <NavLink key={item.name} to={item.href}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                    {item.name}
                  </NavLink>
                ))}
              </div>
              <div className="py-6">
                <span
                  onClick={() => (window.location.href = 'https://app.kwapio.com')}
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Log in
                </span>
              </div>
              <div className="py-6">
                <span
                  onClick={() => (window.location.href = 'https://app.kwapio.com/support/tickets/acc-16e309299f/wos2774aed595')}
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                 Support
                </span>
              </div>
            </div>
            
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  );
};

export default NavBar;