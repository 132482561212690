import React from 'react';
import AccessImg from './icons/accessondemand.svg';
import ManageImg from './icons/manageinoneplatform.svg';
import TeamImg from './icons/teammanagement.svg';

const WhyChoose = () => {
  return (
    <section className="text-gray-400 body-font">
      <div className="container mx-auto">
        <h1 className="sm:text-1xl text-1xl font-medium title-font text-center mb-2" data-aos="fade-up">WE ARE THE BEST
          <br className="hidden sm:block"/>
        </h1>
        <h2 className="text-2xl text-gray-900 font-bold md:text-4xl text-center md:mb-5" data-aos="fade-up"> Why Choose Kwapio</h2>
        <h1 className="sm:text-1xl text-1xl font-medium title-font text-center text-gray-950 mb-2" data-aos="fade-up">Collaboration Unified, Workflow Simplified</h1>
        <h1 className="sm:text-1xl text-1xl font-medium title-font text-center text-gray-950 mb-20" data-aos="fade-up">Discover even more features that benefit your team.</h1>
        <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
          <div className="p-4 md:w-1/3 flex ">
           
            <div className="flex-grow hover:shadow-lg rounded-xl border shadow-md flex flex-col items-center" data-aos="fade-up">
              <img src={AccessImg} alt=''/>
              <h2 className="text-kogreen-dark text-lg title-font font-medium mb-2">Personnel coordination</h2>
              <p className="pl-5 pr-5 pt-5 leading-relaxed text-base text-gray-950 pb-5">Streamline operations, monitor sales, and effortlessly manage user permissions with Kwapio Team.</p>
            </div>

          </div>
          <div className="p-4 md:w-1/3 flex">
            
            <div className="flex-grow hover:shadow-lg rounded-xl border shadow-md flex flex-col items-center" data-aos="fade-up">
              <img src={ManageImg} alt=''/>
              <h2 className="text-kogreen-dark text-lg title-font font-medium mb-2">Instant Access</h2>
              <p className=" pl-5 pr-5 pt-5 leading-relaxed text-base text-gray-950 pb-5">24/7 Connectivity: Empower your team with anytime, anywhere access. Stay connected and take charge.</p>
            </div>
          </div>
          <div className="p-4 md:w-1/3 flex">
            
            <div className="flex-grow hover:shadow-lg rounded-xl border shadow-md flex flex-col items-center" data-aos="fade-up">
              <img src={TeamImg} alt=''/>
              <h2 className="text-kogreen-dark text-lg title-font font-medium mb-2">Unified Control</h2>
              <p className=" pl-5 pr-5 pt-5 leading-relaxed text-base text-gray-950 pb-5">Centralized Management: Streamline and control all aspects in a single platform. Simplify team management</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyChoose;
