import React from 'react';
import styles from './website.module.scss';

const ScrollIndicator = ({ progressWidth}) => {
  return (
    <div className={styles.progressContainer}>
      <div
        className={styles.progressBar}
        style={{ width: `${progressWidth}%`, backgroundColor: "#004b50" }}
      ></div>
    </div>
  );
};

export default ScrollIndicator;
