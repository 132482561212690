import LinkedIn from 'files/linkedin';
import Mail from 'files/mail';
import React from 'react';

const Employee = () => {

  const List =[
    {
      role: 'Software Engineer',
      name : 'Andrews',
      text:'Mastering Go language for backend development involves leveraging its concurrency features and robust standard library for scalable web services.',
      image:"https://raw.githubusercontent.com/kwapio/logo/main/assets/employeePic/Andrews.png",
      email: "andrews@qubernet.com",
      linkedIn: "https://www.linkedin.com/in/andrews-gnana-doss-6449a02a7"
    },
    {
      role: 'Associates Software Engineer',
      name : 'Vijayakumar',
      text:'As a Frontend developer, I excel in translating designs into dynamic, responsive web applications. With strong expertise in React, JavaScript, HTML and CSS .',
      image:'https://raw.githubusercontent.com/kwapio/logo/main/assets/employeePic/Vijayakumar_casual.jpg',
      email: "vijayakumar@qubernet.com",
      linkedIn: "https://www.linkedin.com/in/vijayakumar-k-ar1580/"
    },
    {
      role: 'Technical Product Specialist',
      name : 'Dinesh',
      text:'Software tester responsible for ensuring the quality and functionality of applications through rigorous testing',
      image:'https://raw.githubusercontent.com/kwapio/logo/main/assets/employeePic/Dinesh.png',
      email: "dinesh@qubernet.com",
      linkedIn: "http://linkedin.com/in/dinesh-raj-071325126"
    },
    {
      role: 'Associates Software Engineer',
      name : 'Vijay',
      text:'"Backend developer proficient in Go language, specializing in scalable microservices architecture and efficient API development.',
      image:'https://raw.githubusercontent.com/kwapio/logo/main/assets/employeePic/vijayBackend.png',
      email: "vijay@qubernet.com",
      linkedIn: "https://www.linkedin.com/in/vijay-m-b8a241240"
    },
    {
      role: 'Associates Software Engineer',
      name : 'Nithish',
      text:'As a backend developer at GoLanguage, I design and build efficient server-side applications using the Go programming language',
      image:'https://raw.githubusercontent.com/kwapio/logo/main/assets/employeePic/nithish.png',
      email: "nithishkumar@qubernet.com",
      linkedIn: "https://www.linkedin.com/in/nithish-kumar-r-746373240"
    }
  ];
  

  const Card = ({ image, role, name, text, email, linkedIn }) => {
    return (
      <div className="mb-6 rounded-lg bg-white p-6 shadow-md border-[0.5px]">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <img className="emp mr-2 h-16 w-16 rounded-full object-cover" src={image} alt="profile" />
            <div>
              <h3 className="text-base font-semibold text-gray-900">{name}</h3>
              <span className="block text-xs font-normal text-gray-500">{role}</span>
            </div>
          </div>
          <div className="mt-0 flex items-center justify-start text-sm font-semibold text-gray-900 gap-4">
            <a className="flex items-center" href={linkedIn} target="_blank" rel="noreferrer">
              <LinkedIn width={20} height={20}/>
            </a>
            <a className="flex items-center" href={`mailto:${email}`} target="_blank" rel="noreferrer">
              <Mail width={20} height={20}/>
            </a>
         
          </div>
          {/* <p className="text-sm font-medium text-indigo-500"><span className="mr-0.5">+</span>Follow</p> */}
        </div>
        <p className="my-6 text-sm font-normal text-gray-500">{text}</p>
        
      </div>
    );
  };


  return (
    <div className=" pb-10 container mx-auto">
 
      <div className="px-4">
        <div className="my-20">
        </div>
        <div className="grid sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-3" data-aos="fade-up">
                   
          {
            List?.map((profile)=>(
              <>
                <Card role={profile.role} name={profile.name} text={profile.text} image={profile.image} email={profile.email} linkedIn={profile.linkedIn}/>
              </>
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default Employee;
