import React from 'react';

const Monitoring = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54 54" {...props}>
      <linearGradient
        id="a"
        x1={4.917}
        x2={49.254}
        y1={-3.373}
        y2={48.543}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#d810fb" />
        <stop offset={1} stopColor="#3953ed" />
      </linearGradient>
      <path
        fill="url(#a)"
        d="M46.831 3.48H7.17A4.675 4.675 0 002.5 8.149V36.82a4.675 4.675 0 004.67 4.67h11.703v4.35h-2.192a2.343 2.343 0 00-2.341 2.341c0 1.29 1.05 2.34 2.341 2.34h20.64c1.29 0 2.34-1.05 2.34-2.34a2.343 2.343 0 00-2.34-2.341h-2.192v-4.35h11.703a4.675 4.675 0 004.669-4.67V8.149a4.676 4.676 0 00-4.67-4.669zm-8.171 44.7c0 .739-.601 1.34-1.34 1.34H16.68c-.739 0-1.341-.601-1.341-1.34s.602-1.341 1.341-1.341h20.64c.74 0 1.34.602 1.34 1.341zm-4.532-2.341H19.873v-4.35h14.255v4.35zM50.5 36.82a3.674 3.674 0 01-3.669 3.67H7.17a3.674 3.674 0 01-3.67-3.67V8.149A3.673 3.673 0 017.17 4.48h39.661A3.673 3.673 0 0150.5 8.149V36.82z"
      />
      <linearGradient
        id="b"
        x1={4.917}
        x2={49.254}
        y1={-3.373}
        y2={48.542}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#d810fb" />
        <stop offset={1} stopColor="#3953ed" />
      </linearGradient>
      <path
        fill="url(#b)"
        d="M43.695 7.805h-33.39c-1.654 0-3 1.346-3 3v23.359c0 1.654 1.346 3 3 3h33.39c1.654 0 3-1.346 3-3V10.805c0-1.654-1.345-3-3-3zm2 26.36c0 1.103-.897 2-2 2h-33.39c-1.103 0-2-.897-2-2v-23.36c0-1.103.897-2 2-2h33.39c1.103 0 2 .897 2 2v23.36z"
      />
      <linearGradient
        id="c"
        x1={4.917}
        x2={49.254}
        y1={-3.373}
        y2={48.542}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#d810fb" />
        <stop offset={1} stopColor="#3953ed" />
      </linearGradient>
      <path
        fill="url(#c)"
        d="M27.006 14.879h-.014c-5.427 0-11.53 3.497-15.003 7.267a.5.5 0 000 .678c3.467 3.764 9.546 7.267 15.003 7.267h.014c5.571 0 11.66-3.634 15.006-7.267a.501.501 0 000-.678c-3.472-3.77-9.533-7.267-15.006-7.267zm-.178 14.203c-3.561-.089-6.433-3.014-6.433-6.606 0-3.581 2.87-6.498 6.429-6.588 3.931-.053 6.782 3.046 6.782 6.588-.001 3.543-2.84 6.656-6.778 6.606zm-13.769-6.597c1.049-1.03 4.47-4.138 8.912-5.694a7.578 7.578 0 00.008 11.392c-4.446-1.556-7.871-4.667-8.92-5.698zm18.946 5.707a7.568 7.568 0 00.008-11.411c4.452 1.553 7.88 4.672 8.929 5.704-1.049 1.032-4.48 4.154-8.937 5.707z"
      />
      <linearGradient
        id="d"
        x1={4.917}
        x2={49.254}
        y1={-3.373}
        y2={48.542}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#d810fb" />
        <stop offset={1} stopColor="#3953ed" />
      </linearGradient>
      <path
        fill="url(#d)"
        d="M26.997 19.559a2.927 2.927 0 00-2.923 2.923 2.93 2.93 0 002.923 2.929 2.933 2.933 0 002.93-2.929 2.93 2.93 0 00-2.93-2.923zm0 4.852a1.928 1.928 0 01-1.923-1.929c0-1.061.862-1.923 1.923-1.923 1.064 0 1.93.862 1.93 1.923a1.932 1.932 0 01-1.93 1.929z"
      />
    </svg>
  );
};

export default Monitoring;
