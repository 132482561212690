import React from 'react';

const Mail = (props) => {
  const { width, height } = props;

  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 32 32"
    >
      <path
        fill="#ea4435"
        d="M16.58 19.107L3.89 11.03A3 3 0 017.11 5.97l9.31 5.924 8.36-5.851a3 3 0 013.44 4.915z"
      />
      <path
        fill="#00ac47"
        d="M27.5 26.5h-4v-18a3 3 0 013-3 3 3 0 013 3v16a2 2 0 01-2 2z"
      />
      <path
        fill="#ffba00"
        d="M29.456 8.066c-.009-.06-.008-.122-.02-.182-.02-.091-.055-.176-.083-.265a2.931 2.931 0 00-.096-.3c-.02-.047-.05-.088-.073-.134a2.984 2.984 0 00-.215-.385c-.04-.058-.09-.108-.135-.163a3.028 3.028 0 00-.245-.285c-.067-.064-.142-.118-.215-.177a3.02 3.02 0 00-.24-.185c-.079-.052-.166-.092-.25-.136-.088-.046-.175-.097-.267-.133-.089-.036-.183-.059-.276-.086s-.186-.06-.281-.078a3.054 3.054 0 00-.357-.036c-.076-.005-.151-.019-.227-.018a2.978 2.978 0 00-.422.043c-.056.008-.113.007-.17.019a33.211 33.211 0 00-.564.178c-.051.022-.096.055-.146.08A2.901 2.901 0 0023.5 8.5v5.762l4.72-3.304a2.888 2.888 0 001.236-2.893z"
      />
      <path
        fill="#4285f4"
        d="M5.5 5.5a3 3 0 013 3v18h-4a2 2 0 01-2-2v-16a3 3 0 013-3z"
      />
      <path
        fill="#c52528"
        d="M2.544 8.066c.009-.06.008-.122.02-.182.02-.091.055-.176.083-.265a2.931 2.931 0 01.096-.299c.02-.048.05-.09.073-.135a2.972 2.972 0 01.216-.385c.04-.058.09-.108.134-.163a3.027 3.027 0 01.245-.285c.067-.064.142-.118.215-.177a3 3 0 01.24-.185c.08-.052.166-.092.25-.136a2.975 2.975 0 01.267-.134c.089-.036.184-.059.277-.086s.185-.06.28-.078a3.057 3.057 0 01.357-.036c.076-.005.151-.018.228-.018a2.976 2.976 0 01.421.043c.057.008.113.008.17.02a2.906 2.906 0 01.285.088 2.916 2.916 0 01.279.09c.051.021.096.054.146.079a2.974 2.974 0 01.375.21A2.994 2.994 0 018.5 8.5v5.762l-4.72-3.304a2.89 2.89 0 01-1.236-2.892z"
      />
    </svg>
  );
};

export default Mail;
