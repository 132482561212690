import React from 'react';

const Workspace = (props) => {
  const { width, height } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      width={width}
      height={height}
    >
      <defs>
        <linearGradient
          id="a"
          x1={0.236}
          x2={1706.43}
          y1={853.327}
          y2={853.327}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#f071ab" />
          <stop offset={1} stopColor="#22abe2" />
        </linearGradient>
      </defs>
      <rect width={width} height={height} fill="none" />
      <circle
        cx={128}
        cy={68}
        r={40}
        fill="none"
        stroke="url(#a)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={8}
        className="colorStroke000 svgStroke"
      />
      <circle
        cx={188}
        cy={172}
        r={40}
        fill="none"
        stroke="url(#a)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={8}
        className="colorStroke000 svgStroke"
      />
      <circle
        cx={68}
        cy={172}
        r={40}
        fill="none"
        stroke="url(#a)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={8}
        className="colorStroke000 svgStroke"
      />
    </svg>
  );
};

export default Workspace;
