import React from "react";
function Applestore(props) {
  const { width, height } = props;
  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 120 120"
      {...props}
    >
      <defs>
        <linearGradient id="b" x1="50%" x2="50%" y1="0%" y2="100%">
          <stop offset="0%" stopColor="#06C4FD" />
          <stop offset="100%" stopColor="#1569F4" />
        </linearGradient>
        <rect id="a" width={120} height={120} x={0} y={0} rx={28} />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="c" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <use fill="url(#b)" xlinkHref="#a" />
        <path
          fill="#FFF"
          d="M83.226 68.906h13.805a5.625 5.625 0 110 11.25h-7.355l5.042 8.795a5.642 5.642 0 01-2.073 7.692 5.638 5.638 0 01-7.695-2.052L63.86 58.249c-.004-.006-1.246-2.223-.847-7.3.205-2.61 1.722-5.729 4.552-9.358l15.661 27.315zM59.607 28.05l2.15-3.725A5.625 5.625 0 1171.5 29.95L49.01 68.906h17.204c.63.196 5.595 3.36 5.64 7.95.015 1.492-.282 2.592-.892 3.3H21.797a5.625 5.625 0 010-11.25h14.222L53.112 39.3l-5.534-9.585a5.625 5.625 0 119.743-5.625l2.286 3.96zM28.043 83.133s1.263-1.04 4.14-.982c1.92.04 4.39 1.217 7.416 3.534l-5.567 8.988a5.801 5.801 0 01-7.832 1.97 5.334 5.334 0 01-1.846-7.464c1.189-1.885 2.418-3.9 3.69-6.046z"
          mask="url(#c)"
        />
      </g>
    </svg>
  );
}


export default Applestore;