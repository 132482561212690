import Aos from 'aos';
import './App.css';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import WebSite from 'pages';
import { BrowserRouter as Router } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import Career from 'pages/career';
import NavBar from 'pages/navbar';
import NoJobs from 'pages/career/nojobs';
function App() {
  useEffect(()=>{
    Aos.init();
  });
  
  return (
  
    <Router>
      <NavBar />
      <Routes>

        <Route path='/' element={<WebSite />} />
        <Route path='career' element={<Career />} />
        <Route path='career/nojobs' element={<NoJobs />} />
        <Route path='*' element={<div>404 page not found</div>} />
      </Routes>
    </Router>
  );
}

export default App;
