import React from 'react';


const List =[
  {
    name : 'Backend Developer',
    text:'"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation'

  },
  {
    name : 'Frontend Developer',
    text:'"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation'

  },
  {
    name : 'Software Tester',
    text:'"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation'

  },
  {
    name : 'Human Resource',
    text:'"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation'
 
  },
  {
    name : 'Admin',
    text:'"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation'
    
  }
];


const Card = ({  role, name, text, email, linkedIn }) => {
  return (
    <div className="mb-6 rounded-lg bg-white p-6 shadow-md border-[0.5px]">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <div>
            <h3 className="text-base font-semibold text-gray-900">{name}</h3>
            <span className="block text-xs font-normal text-gray-500">{role}</span>
          </div>
        </div>
        
      </div>
      <p className="my-6 text-sm font-normal text-gray-500">{text}</p>
      <div className="mt-0 flex items-center justify-start text-sm font-semibold text-gray-900 gap-4">
        <a className="flex items-center underline cursor-pointer" href='career/nojobs' target="_blank" rel="noreferrer">
           Click here to apply
        </a>
      
       
      </div>
    </div>
  );
};


const Career = () => {

  return (
    <div className=" bg-white">
      <h2 className="text-2xl text-gray-900 font-bold md:text-4xl text-center">Join us our Team!</h2>
      <div className="container m-auto px-6 text-gray-600 md:px-12 xl:px-6 ">
        <div className="space-y-6 md:space-y-0 md:flex md:gap-6 lg:items-center lg:gap-12">
          
         
          <div className="md:7/12 lg:w-6/12 lg:ps-72">

            <div className=''>
          
              <p className="mt-6 text-gray-600 font-bold ">Career at Kwapio!</p>
              <p className="mt-4 text-gray-600 font-bold text-3xl">Work with us.</p>
              <p className="mt-4 text-gray-600 ">Explore remote-friendly, flexible opportunities and join our mission to make  work life simpler, more pleasant and more productive.  </p>


              <button className='mt-4 bg-kogreen-dark p-3 text-gray-50 rounded mb-4'>
                <a href='career/nojobs'>Apply for Jobs</a>
              </button>
              <br/>
              <a href='career/nojobs' className='underline text-pink-600'>Looking for internship?</a>

            </div>

          </div>

          <div className="md:5/12 lg:w-5/12">
            <img src='https://raw.githubusercontent.com/kwapio/logo/main/assets/invoiceImg.png' alt=''/>
          </div>
        </div>
      </div>


    

      <section className="text-gray-700 body-font border-gray-200">
        <p className="mt-4 text-gray-600 font-bold text-3xl text-center">Available Jobs</p>
        <div className=" pb-10 container mx-auto">
 
          <div className="px-4">
            <div className="my-5">
            </div>
            <div className="grid sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-3">
              
              {
                List?.map((profile)=>(
                  <>
                    <Card role={profile.role} name={profile.name} text={profile.text} image={profile.image} email={profile.email} linkedIn={profile.linkedIn}/>
                  </>
                ))
              }
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Career;
