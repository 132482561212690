import React from "react";
function AppLogo(props) {
  const { width, height } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 389.07 108.74">
      <polygon fill="#5fbd70" points="352.69 39.25 350.14 24.97 352.69 20.25 355.25 24.97 352.69 39.25" />
      <polygon fill="#77449a" points="352.69 65.2 355.25 79.49 352.69 84.21 350.14 79.49 352.69 65.2" />
      <polygon fill="#82c352" points="346.24 40.83 336.88 29.74 336.73 24.37 341.31 27.18 346.24 40.83" />
      <polygon fill="#5f5ca7" points="359.21 63.31 368.57 74.4 368.71 79.77 364.14 76.96 359.21 63.31" />
      <polygon fill="#afc136" points="341.43 45.43 327.78 40.5 324.97 35.93 330.34 36.08 341.43 45.43" />
      <polygon fill="#4f73b7" points="363.91 58.41 377.56 63.34 380.37 67.91 375 67.76 363.91 58.41" />
      <polygon fill="#dcc125" points="339.57 51.81 325.29 54.37 320.56 51.81 325.29 49.26 339.57 51.81" />
      <polygon fill="#4f8ecb" points="365.52 51.81 379.81 49.26 384.53 51.81 379.81 54.37 365.52 51.81" />
      <polygon fill="#d2516c" points="341.15 58.27 330.06 67.63 324.69 67.78 327.5 63.2 341.15 58.27" />
      <polygon fill="#34a5cd" points="363.62 45.3 374.72 35.94 380.08 35.8 377.27 40.37 363.62 45.3" />
      <polygon fill="#a7458b" points="345.75 63.08 340.82 76.73 336.25 79.54 336.39 74.17 345.75 63.08" />
      <polygon fill="#44b49e" points="358.72 40.6 363.65 26.95 368.23 24.14 368.08 29.51 358.72 40.6" />
      <polygon fill="#44b49e" points="360.59 17.59 362.1 15.68 362.57 18.07 360 24.34 360.59 17.59" />
      <polygon fill="#a7458b" points="342.66 85.72 345.16 79.42 344.64 86.18 343.15 88.11 342.66 85.72" />
      <polygon fill="#5fbd70" points="342.45 18.24 342.8 15.83 344.4 17.66 345.31 24.38 342.45 18.24" />
      <polygon fill="#77449a" points="360.99 86.21 360 79.5 362.93 85.61 362.6 88.03 360.99 86.21" />
      <polygon fill="#82c352" points="327.06 27.87 326.16 25.61 328.46 26.4 332.61 31.76 327.06 27.87" />
      <polygon fill="#5f5ca7" points="377.1 77.47 372.89 72.15 378.48 75.98 379.41 78.23 377.1 77.47" />
      <polygon fill="#afc136" points="318.55 43.91 316.64 42.4 319.03 41.94 325.3 44.5 318.55 43.91" />
      <polygon fill="#4f73b7" points="380.38 59.34 387.14 59.86 389.07 61.35 386.68 61.84 380.38 59.34" />
      <polygon fill="#dcc125" points="316.79 61.7 318.62 60.1 325.34 59.19 319.2 62.05 316.79 61.7" />
      <polygon fill="#4f8ecb" points="386.57 41.57 388.99 41.9 387.17 43.52 380.46 44.5 386.57 41.57" />
      <polygon fill="#d2516c" points="327.36 76.04 332.71 71.89 328.83 77.44 326.57 78.34 327.36 76.04" />
      <polygon fill="#34a5cd" points="376.94 26.02 379.19 25.09 378.43 27.4 373.11 31.61 376.94 26.02" />
      <path fill="#1d0a39" d="M12,48.36V81.13H0V7.9H12V41.3L40.46,7.9H55.53L23.71,44.57,56.06,81.13H40.36Z" />
      <path fill="#1d0a39"
        d="M59.85,23.08H72.07L84.19,69.75,96.52,23.08h12.54l11.8,46.46,12-46.46h11.8l-18,58.05H114l-11.7-42.88L90.62,81.13H78Z" />
      <path fill="#1d0a39"
        d="M177,22.13c9.8,0,16.54,4.63,20.13,9.37V23.08h12.11V81.13H197.15V72.49c-3.69,5-10.65,9.59-20.34,9.59-15.07,0-27.18-12.33-27.18-30.24S161.74,22.13,177,22.13Zm2.53,10.43c-9,0-17.6,6.74-17.6,19.28s8.64,19.81,17.6,19.81,17.6-7.06,17.6-19.6S188.61,32.56,179.55,32.56Z" />
      <path fill="#1d0a39"
        d="M257.31,22.13c15.28,0,27.29,11.8,27.29,29.71s-12,30.24-27.29,30.24a26.24,26.24,0,0,1-20.23-9.38v36h-12V23.08h12v8.53A25.33,25.33,0,0,1,257.31,22.13Zm-2.63,10.43c-9,0-17.6,7.06-17.6,19.49s8.64,19.6,17.6,19.6,17.7-7.27,17.7-19.81S263.74,32.56,254.68,32.56Z" />
      <path fill="#1d0a39" d="M294.93,7.69a7.64,7.64,0,1,1,15.28,0,7.64,7.64,0,1,1-15.28,0Zm1.58,15.39h12V81.13h-12Z" />
    </svg>
  );
}


export default AppLogo;