import React from 'react';

const Twitter = (props) => {
  const { width, height } = props;

  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" {...props}>
      <path d="M69.7 8.7H30.4C18.7 8.7 9.1 18.2 9.1 30v41.2c0 11.7 9.5 21.3 21.3 21.3h39.4c11.7 0 21.3-9.5 21.3-21.3V29.9C91 18.2 81.5 8.7 69.7 8.7zm7.1 69.6H61.4c-.1 0-.2 0-.2-.1L46.8 57.3h-.1C40.9 64 35.2 70.6 29.6 77.1c-.3.4-.7.7-1 1-.1.1-.2.1-.3.1h-4.2c-.1 0-.2 0-.1-.1l20.5-23.8c.1-.1.1-.2 0-.3L24.1 24.3v-.1h15.5c.1 0 .1 0 .2.1L53.4 44h.1l16.9-19.7c.1-.1.2-.1.3-.1h4.2c.2 0 .2.1.1.2L55.6 46.9c-.1.1-.1.2 0 .3l21.3 31s0 .1-.1.1z" />
      <path d="M37.8 27.8h-6.7c-.1 0-.1.1-.1.2l32.2 46.7H69.9c.1 0 .1-.1.1-.2L37.8 27.8z" />
    </svg>
  );
};

export default Twitter;
