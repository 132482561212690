import Logocrystal from 'files/logocrystal';
import React from 'react';

const About = () => {
  return (<>
    <div className="py-16 bg-white">
      <h2 className="text-2xl text-gray-900 font-bold md:text-4xl text-center" data-aos="fade-up"> About us</h2>
      <div className="container m-auto px-6 text-gray-600 md:px-12 xl:px-6">
        <div className="space-y-6 md:space-y-0 md:flex md:gap-6 lg:items-center lg:gap-12">
          <div className="md:5/12 lg:w-4/12">
            <Logocrystal />
          </div>
          <div className="md:7/12 lg:w-6/12">
            <p className="mt-6 text-gray-600" data-aos="fade-up">Welcome to Kwapio!</p>
            <p className="mt-4 text-gray-600" data-aos="fade-up"> At Kwapio, we’re dedicated to  revolutionizing the way people experience digital media. Our mission is to  provide innovative solutions that enhance user engagement and satisfaction.</p>
            <p className="mt-4 text-gray-600" data-aos="fade-up"> Founded in 2024, Kwapio has grown from a small startup to a leading  technology provider thanks to our commitment to quality, customer satisfaction, and continuous improvement. Our team of experts brings  years of industry experience and a passion for cutting-edge technology to every project we undertake.            </p>
            <p className="mt-4 text-gray-600" data-aos="fade-up"> We believe in creating solutions that are not only effective but also intuitive and user-friendly.By focusing on innovation, customer feedback, and a collaborative approach, we aim to deliver products that exceed expectations and drive success.</p>
            <p className="mt-4 text-gray-600" data-aos="fade-up">Thank you for visiting Kwapio. We look forward to working with you and helping you achieve your goals.</p>
          </div>
        </div>
      </div>
    </div>
  </>
  );
};

export default About;
